// tslint:disable:variable-name
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, retryWhen, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { AuthModel } from 'src/app/modules/auth/_models/auth.model';
import { environment } from '../../../../../environments/environment';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }
  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  protected http: HttpClient;
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;
  constructor(http: HttpClient) {
    this.http = http;
  }
  getpackageHttp(){
    return environment.packageHttp
  }
  // manitinece
  updateMaintenanceStatus(data): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/maintenance.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<any>(url, data, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('error', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  updateAlowIP(data): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/update.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<any>(url, data, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('error', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getMaintenanceAlowdIp(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/read.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.get<any>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('error', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  // CREATE
  // server should return the object with ID
  create(item: BaseModel): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/create.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    console.log("create item", item);
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/find.php';
    console.log("find url", url);
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(url, tableState, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    );
  }

  getItemById(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._errorMessage.next('');
    const url = `${this.API_URL}/read.php?id=${id}`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getAllItems(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_all.php`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getAllItemsId(id: number): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_all_id.php?id=${id}`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getChildren(id: number): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_children.php?id=${id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET CHILDREN', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getClaimChildren(id: number): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${environment.apiUrl}/claim/read_by_parent.php?id=${id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET claim CHILDREN', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getDetails(id: number): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/get_details.php?id=${id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET DETAILS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getchiildById(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);  
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_id.php?id=${id}`;
    return this.http.get<BaseModel>(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  updagechildMete(child: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/update_mete.php`;
    return this.http.post(url, child).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', child, err);
        return of(child);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getAllItemstoken(token): Observable<BaseModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/get-free-postage.php`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  updateWithoutIdWithToken(item: BaseModel, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const url = `${this.API_URL}/put-free-postage.php`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }


  // UPDATE
  update(item: BaseModel): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = `${this.API_URL}/update.php?id=${item.id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  editChilde(child: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    console.log("service called")
    const url = `${this.API_URL}/edit.php`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post(url, child, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', child, err);
        return of(child);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    console.log(body,"body--------")
    const url = this.API_URL + '/update_status.php';
    return this.http.put(url, body, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/delete.php?id=${id}`;
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/delete_items.php';
    const body = { ids };
    return this.http.put(url, body, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    // console.log("REQUEST");
    console.log("table state ---------------", this._tableState$.value);
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
          console.log("RESPONSE");
          console.log(res, "hello");
          console.log(res.total, "total");
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          console.log("Error");
          console.log(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    console.log("FINAL");
    console.log(request);
    this._subscriptions.push(request);
  }

  upload(product_id: number, file: File): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const formData: FormData = new FormData();
    formData.append('product_id', product_id.toString());
    formData.append('file', file);
    // const req = new HttpRequest('POST', `${this.API_URL}/upload.php`, formData, {
    const req = this.http.post(`${this.API_URL}/upload.php`, formData, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
      // });
    }).pipe(
      map((event) => {
        // switch (event.type) {

        //   case HttpEventType.UploadProgress:
        //     console.log("HTTP UPLOADPROGRESS", event.loaded, event.total);
        //     const progress = Math.round(100 * event.loaded / event.total);
        //     return { status: 'progress', message: progress };

        //   case HttpEventType.Response:
        //     console.log("HTTP RESPONSE", event.body);
        //     return event.body;

        //   default:
        //     return `Unhandled event: ${event.type}`;
        // }
        return event;
      })
    );
    console.log("UPLOAD REQUEST", req);
    return req;
    // return this.http.request(req);
  }

  getFiles(productId: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${this.API_URL}/files.php?pid=${productId}`, { headers: httpHeaders });
  }

  deleteImage(id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/delete_image.php?id=${id}`;
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE IMAGE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  upload_photo(file: File, school_id: string, upload_type: string): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('school_id', school_id);
    formData.append('upload_type', upload_type);
    // const req = new HttpRequest('POST', `${this.API_URL}/upload.php`, formData, {
    const req = this.http.post(`${this.API_URL}/upload.php`, formData, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
      // });
    }).pipe(
      map((event) => {
        // switch (event.type) {

        //   case HttpEventType.UploadProgress:
        //     console.log("HTTP UPLOADPROGRESS", event.loaded, event.total);
        //     const progress = Math.round(100 * event.loaded / event.total);
        //     return { status: 'progress', message: progress };

        //   case HttpEventType.Response:
        //     console.log("HTTP RESPONSE", event.body);
        //     return event.body;

        //   default:
        //     return `Unhandled event: ${event.type}`;
        // }
        return event;
      })
    );
    // console.log("UPLOAD REQUEST", req);
    return req;
    // return this.http.request(req);
  }
  upload_old_photo(file: File, school_id: string, upload_type: string): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('school_id', school_id);
    formData.append('upload_type', upload_type);
    // const req = new HttpRequest('POST', `${this.API_URL}/upload.php`, formData, {
    const req = this.http.post(`${this.API_URL}/old_uplaod.php`, formData, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
      // });
    }).pipe(
      map((event) => {
        return event;
      })
    );
    // console.log("UPLOAD REQUEST", req);
    return req;
    // return this.http.request(req);
  }

  export_orders(order_id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${this.API_URL}/download_pdf.php?id=${order_id}`, { headers: httpHeaders, responseType: 'blob' });
  }

  export_prepay_orders(req: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.post(`${this.API_URL}/export.php`, req, { headers: httpHeaders, responseType: 'blob' });
  }

  export_prepays(school_id: number): Observable<any> {

    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${this.API_URL}/export_prepays.php?sid=${school_id}`, { headers: httpHeaders, responseType: 'blob' });
  }

  searchAll(query: string): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${this.API_URL}/search.php?q=${query}`, { headers: httpHeaders });
  }


  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }

  exporting(ids:any): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_all_id.php`;
    return this.http.post<BaseModel>(url,ids, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  exportingPost(item: BaseModel): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    // const url = this.API_URL + '/create.php';
    const url = "assets/csutomer-api.json";
    this._isLoading$.next(true);
    this._errorMessage.next('');
    console.log("create item", item);
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }


  saveCustomEmail(item: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = `${this.API_URL}/order_save.php`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    console.log("save custom email", item);
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('save custom email', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  saveCustomEmailById(item: any, id: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = `${this.API_URL}/save_template.php?id=${id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    console.log("save custom email", item);
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('save custom email', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getCustomEmail(): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/order_get.php`;

    return this.http.get<any>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('Get custom detail', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getCustomEmailById(id: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/get_template.php?id=${id}`;

    return this.http.get<any>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('Get custom detail', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
}
